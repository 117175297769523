/* eslint-disable sonarjs/cognitive-complexity */
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Typography } from '@/components/Atoms';
import { GreenTick } from '@/components/Svg';
import { IData2, RoadmapType } from '@/types/gameRoadMap';
import { useStore } from '@/zustand/store/store';
import CustomRoadmapProgress from './_customProgress';
import styles from './cardItem.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingBalls from '@/components/Common/LoadingBalls';
import { roadmapQueryType } from '../..';
import useTranslationStatus from '@/hooks/useTranslationStatus';

const CardItem = ({
  data,
  isHomePage = false,
  fetchMoreData,
  hasMoreData,
  scrollableTarget,
  filterState,
}: {
  data: IData2['road_maps'];
  isHomePage: boolean;
  fetchMoreData: any;
  hasMoreData: boolean;
  scrollableTarget: string;
  filterState: string;
}) => {
  const { t } = useTranslation('home');

  const sortedData = useMemo(
    () =>
      // @ts-ignore
      [...data].sort(({ Index: indexA, UpdateAt: updateA }, { Index: indexB, UpdateAt: updateB }) => {
        if (indexA === indexB) {
          return updateB - updateA;
        }
        return indexB - indexA;
      }),
    [data],
  );

  const listItem = {
    all: sortedData,
    inProgress: sortedData.filter((item: RoadmapType) => item.IsDone === false),
    done: sortedData.filter((item: RoadmapType) => item.IsDone === true),
  };

  const { setModalData } = useStore();
  const { isReady } = useTranslationStatus();
  return (
    <div className={`${styles.container} ${isHomePage ? '2xl:w-[450px]' : '2xl:w-[526px]'}`}>
      <div className={`${styles.bodyContainer} ${!isHomePage && 'h-full'}`}>
        <InfiniteScroll
          dataLength={listItem[filterState].length}
          next={fetchMoreData}
          hasMore={hasMoreData}
          scrollableTarget={scrollableTarget} //
          loader={<LoadingBalls />}
        >
          {listItem[filterState].length > 0 ? (
            listItem[filterState].map((item: RoadmapType) => {
              const {
                Title,
                Description,
                ImageThumbnail: imageSrc,
                IsDone: isItemDone,
                CurrentStep,
                DefaultImage,
                MaxStep,
                IsCommunity,
                Prefix,
                StartDate,
                EndDate,
                Selected,
                Id,
                // @ts-ignore
                children,
              } = item;

              const formattedStartDate = moment(StartDate).format('MMMM, YYYY');
              const formattedEndDate = moment(EndDate).format('MMMM, YYYY');

              const newMax = MaxStep + 1;
              let newCurrent = CurrentStep;
              if (isItemDone) newCurrent += 1;

              return (
                <div key={Id} className={classNames(styles.cardItem, `gtm-roadmap-click-${Id}`)} onClick={() => setModalData(item)}>
                  <div className={styles.bodyItemContainer}>
                    <div className='flex gap-2 items-center'>
                      <div className={styles.image}>
                        <Image
                          src={
                            Selected && Selected.ImageSmall
                              ? `${Prefix}${Selected.ImageSmall}`
                              : Selected && Selected.DefaultImage
                              ? Selected.DefaultImage
                              : imageSrc
                              ? `${Prefix}${imageSrc}`
                              : DefaultImage
                          }
                          alt='planet image'
                          layout='fill'
                          objectFit='cover'
                          placeholder='blur'
                          blurDataURL='/images/NFT.svg'
                          loading='lazy'
                          className='rounded-md'
                        />
                      </div>
                      <div className='flex flex-col'>
                        <div className={styles.informationContainer}>
                          <Typography className={`${styles.title} lg:hidden flex w-[180px]`} Component='div'>
                            {Selected ? Selected.Title : Title}
                          </Typography>
                        </div>
                        <div className='text-[12px] md:text-[14px] text-[#808080] lg:hidden flex'>
                          {t('home:roadmap.start_on')} {formattedStartDate}
                        </div>
                        {isItemDone && EndDate > 0 ? (
                          <div className='lg:hidden flex w-auto'>
                            <Typography className='sm:text-[14px] text-[12px] text-[#717275]' Component='div'>
                              {isReady && t('home:roadmap.accomplishedOn')}
                            </Typography>
                            <Typography className={styles.dataContainer} Component='div'>
                              <div className='text-[#00FF00] px-1'>{formattedEndDate}</div>
                              <div className={styles.tickContainer}>
                                <GreenTick className='!text-white bg-[#00FF00] rounded-full' stroke='black' />
                              </div>
                            </Typography>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={styles.informationContainer}>
                      <Typography className={`${styles.title} hidden lg:flex`} Component='div'>
                        {Selected ? Selected.Title : Title}
                      </Typography>
                      <Typography className={`${styles.desc} mt-2 lg:mt-0`} Component='div'>
                        {Selected ? Selected.Description : Description || ''}
                      </Typography>
                    </div>
                  </div>
                  <CustomRoadmapProgress currentStep={newCurrent} maxStep={children?.length + 1} />

                  <div className={styles.separatorContainer}>
                    <div className='flex justify-center items-center gap-2'>
                      {IsCommunity && (
                        <div className='text-[#FD8A4C] flex items-center justify-center text-[13px] gap-2'>
                          <div className='w-[6px] h-[6px] bg-[#FD8A4C] rounded-full'></div>
                          <Typography>Community Suggested</Typography>
                        </div>
                      )}
                      <div className='text-[12px] md:text-[14px] text-[#808080] lg:flex hidden'>Start on {formattedStartDate}</div>
                    </div>

                    {isItemDone && EndDate > 0 ? (
                      <div className='w-auto hidden lg:flex'>
                        <Typography className='sm:text-[14px] text-[12px] text-[#717275]' Component='div'>
                          {isReady && t('home:roadmap.accomplishedOn')}
                        </Typography>
                        <Typography className={styles.dataContainer} Component='div'>
                          <div className='text-[#00FF00] px-1'>{formattedEndDate}</div>
                          <div className={styles.tickContainer}>
                            <GreenTick className='!text-white bg-[#00FF00] rounded-full' stroke='black' />
                          </div>
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <div className='mt-2 font-bold text-center'>
              {filterState === 'done' ? t('home:roadmap.empthy_completed') : t('home:roadmap.emthy_list')}
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default CardItem;
