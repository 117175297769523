import React, { useEffect, useState } from 'react';
import api from '@/api/services/gameRoadMap';
import LoadingBalls from '@/components/Common/LoadingBalls';
import { IData2 } from '@/types/gameRoadMap';
import CardItem from './components/CardItem';
import styles from './roadMapItem.module.scss';
import CardItemHeader from './components/CardItemHeader';
import ExtraInformationModal from './components/ExtraInformationModal';

const ROADMAP_CONSTS = {
  'Short Term': {
    type_name: 'Short Term',
    type_title: 'Short Term',
    type_description: '(less than a month)',
    color: '#FD8A4C',
    color_gradient: 'linear-gradient(0deg, rgba(253,154,79,1) 10%, rgba(254,128,74,1) 99%)',
  },
  'Medium Term': {
    type_name: 'Medium Term',
    type_title: 'Medium Term',
    type_description: '(4-12 weeks)',
    color: '#EE67D6',
    color_gradient: 'linear-gradient(180deg, rgba(164,63,178,1) 0%, rgba(182,63,151,1) 50%)',
  },
  'Long Term': {
    type_name: 'Long Term',
    type_title: 'Long Term',
    type_description: '(more than 12 weeks)',
    color: '#42BDFC',
    color_gradient: 'linear-gradient(180deg, rgba(77,80,163,1) 0%, rgba(59,134,194,1) 50%)',
  },
};

const PAGE_SIZE = 10;

type IProps = { isHomePage?: boolean };
export type roadmapQueryType = { type_name: 'Short Term' | 'Long Term' | 'Medium Term'; page_size: number; page_number: number };

const RoadMapItems = ({ isHomePage = false }: IProps) => {
  const [roadmapState, setRoadmapState] = useState({
    'Short Term': {
      data: [] as IData2['road_maps'],
      loading: true,
      pageNumber: 1,
      totalCount: 0,
      fetchingMore: false,
      filterState: 'all' as 'all' | 'inProgress' | 'done',
    },
    'Medium Term': {
      data: [] as IData2['road_maps'],
      loading: true,
      pageNumber: 1,
      totalCount: 0,
      fetchingMore: false,
      filterState: 'all' as 'all' | 'inProgress' | 'done',
    },
    'Long Term': {
      data: [] as IData2['road_maps'],
      loading: true,
      pageNumber: 1,
      totalCount: 0,
      fetchingMore: false,
      filterState: 'all' as 'all' | 'inProgress' | 'done',
    },
  });

  const fetchData = async (type: roadmapQueryType['type_name'], page: number) => {
    setRoadmapState((prev) => ({
      ...prev,
      [type]: { ...prev[type], loading: true },
    }));

    try {
      const response = await api.getGameRoadMap({ type_name: type, page_size: PAGE_SIZE, page_number: page });
      const newData = response?.data?.result?.data;
      const totalItems = response?.data?.result?.total_items || 0;

      setRoadmapState((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          totalCount: totalItems,
          data: [...prev[type].data, ...newData.filter((item) => !prev[type].data.some((existingItem) => existingItem.Id === item.Id))],
          loading: false,
        },
      }));
    } catch (error) {
      console.error(`Error fetching data for ${type}:`, error);
      setRoadmapState((prev) => ({
        ...prev,
        [type]: { ...prev[type], loading: false },
      }));
    }
  };

  useEffect(() => {
    fetchData('Short Term', 1);
    fetchData('Medium Term', 1);
    fetchData('Long Term', 1);
  }, []);

  const fetchMoreData = (type: roadmapQueryType['type_name']) => {
    if (roadmapState[type].fetchingMore) return;

    setRoadmapState((prev) => ({
      ...prev,
      [type]: { ...prev[type], fetchingMore: true, pageNumber: prev[type].pageNumber + 1 },
    }));

    fetchData(type, roadmapState[type].pageNumber + 1).then(() => {
      setRoadmapState((prev) => ({
        ...prev,
        [type]: { ...prev[type], fetchingMore: false },
      }));
    });
  };

  const handleFilterChange = (type: roadmapQueryType['type_name'], newFilter: 'all' | 'inProgress' | 'done') => {
    setRoadmapState((prev) => ({
      ...prev,
      [type]: { ...prev[type], filterState: newFilter },
    }));
  };

  if (Object.values(roadmapState).every((state) => state.loading)) return <LoadingBalls />;

  return (
    <div className={styles.container}>
      {(['Short Term', 'Medium Term', 'Long Term'] as const).map((type) => (
        <div key={type} className=''>
          <CardItemHeader
            {...ROADMAP_CONSTS[type]}
            setFilterState={(newFilter) => handleFilterChange(type, newFilter as any)}
            filterState={roadmapState[type].filterState}
          />

          <div key={type} id={`${type.toLowerCase().replace(' ', '-')}-scroll`} className={styles.scrollableContainer}>
            <CardItem
              isHomePage={isHomePage}
              data={roadmapState[type].data}
              filterState={roadmapState[type].filterState}
              fetchMoreData={() => fetchMoreData(type)}
              hasMoreData={roadmapState[type].data.length < roadmapState[type].totalCount}
              scrollableTarget={`${type.toLowerCase().replace(' ', '-')}-scroll`}
            />
          </div>
        </div>
      ))}
      <ExtraInformationModal />
    </div>
  );
};

export default RoadMapItems;
