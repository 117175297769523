import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import { LayoutGroup, motion } from 'framer-motion';
import Image from 'next/image';
import YoutubeVideoPlayer from '@/components/TokenSale/Header/VideoPlayer/YoutubeVideoPlayer';
import VideosStyles from './Videos.module.scss';

interface IProps {
  posterUrl: string;
  url?: string;
  isCustomUrl?: boolean;
  className?: string;
}

const RoadmapVideo = ({ posterUrl, url, isCustomUrl, className }: IProps) => {
  const [playing, setPlaying] = useState(false);
  const [open, setOpen] = useState(false);
  const isVideo = url ? true : false;
  const backgroundUrl = `${posterUrl}`;
  const [zoom, setZoom] = useState(false);

  const playHandler = () => {
    setPlaying(true);
    setOpen(true);
    setZoom(true);
  };

  const pauseHandler = () => {
    setPlaying(false);
    setZoom(false);
  };

  return (
    <div
      className={classNames('relative rounded-lg overflow-hidden', className)}
      onClick={() => {
        setZoom(!zoom);
      }}
    >
      <div className='h-full w-full relative overflow-hidden'>
        <img src={url} alt='fuel image' className='relative invisible overflow-hidden' />
        <LayoutGroup>
          <motion.div layout className={classNames('w-full h-full top-0 left-0 overflow-hidden', !zoom ? 'absolute z-10' : 'fixed z-50')}>
            <motion.div onClick={pauseHandler} layout className={classNames('inset-0 bg-black/50 absolute', !zoom ? 'opacity-0' : 'opacity-100')} />
            <motion.div
              layout
              className={classNames(
                'm-auto relative h-full flex justify-center items-center',
                !zoom ? 'rounded-[6px] w-full object-contain' : 'w-full sm:w-3/4',
              )}
              onClick={playing ? pauseHandler : playHandler}
            >
              {open && isVideo && playing ? (
                !isCustomUrl ? (
                  <YoutubeVideoPlayer className={classNames('h-auto w-full')} url={url} autoPlay />
                ) : (
                  <ReactPlayer
                    onEnded={() => pauseHandler()}
                    url={url}
                    controls={true}
                    playing={playing}
                    //   onPlay={playHandler}
                    //   onPause={pauseHandler}
                    width='100%'
                    height='auto'
                    config={{
                      file: {
                        attributes: {
                          poster: backgroundUrl,
                        },
                      },
                    }}
                  />
                )
              ) : (
                <div>
                  {/* Circle */}
                  {isVideo && !playing && (
                    <div className={VideosStyles.circle} onClick={playHandler}>
                      {/* Triangle */}
                      <div className={VideosStyles.triangle} />
                    </div>
                  )}

                  <Image src={backgroundUrl} objectFit='cover' layout='fill' alt='background' />
                </div>
              )}
            </motion.div>
          </motion.div>
        </LayoutGroup>
      </div>
    </div>
  );
};

export default RoadmapVideo;
