/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { useTranslation } from 'next-i18next';
// import { useRouter } from 'next/router';
import { Typography } from '@/components/Atoms';
import { IData2 } from '@/types/gameRoadMap';
import styles from './cardItemHeader.module.scss';
import useTranslationStatus from '@/hooks/useTranslationStatus';

interface IProps {
  filterState: 'all' | 'inProgress' | 'done';
  setFilterState: React.Dispatch<React.SetStateAction<'all' | 'inProgress' | 'done'>>;
  color_gradient: string;
  type_title: string;
  type_description: string;
  color: string;
}

const CardItemHeader = (props: IProps) => {
  const { t } = useTranslation('seasonZero');
  const { isReady } = useTranslationStatus();
  const { filterState, setFilterState, color, color_gradient, type_description, type_title } = props;
  const activeClass = `!font-bold`;

  return (
    <div
      className={styles.headerContainer}
      style={{
        background: color_gradient,
      }}
    >
      <div className={styles.titleContainer}>
        <Typography className={styles.title} Component={'div'} size='s28ToS15'>
          {type_title}
        </Typography>
        <Typography className={styles.desc} Component={'div'} size='s16ToS12'>
          {type_description}
        </Typography>
      </div>

      <div className={styles.filterContainer}>
        <Typography
          onClick={() => {
            setFilterState('all');
          }}
          style={{
            color: filterState === 'all' ? color : 'white',
          }}
          className={`${styles.filterItem} ${filterState === 'all' ? activeClass : ''}`}
        >
          All
        </Typography>
        <Typography className={styles.filterItem}>|</Typography>
        <Typography
          onClick={() => {
            setFilterState('inProgress');
          }}
          style={{
            color: filterState === 'inProgress' ? color : 'white',
          }}
          className={`${styles.filterItem} ${filterState === 'inProgress' ? activeClass : ''}`}
        >
          {isReady && t('seasonZero:GAME_ROADMAP.general.inProgress')}
        </Typography>
        <Typography className={styles.filterItem}>|</Typography>
        <Typography
          onClick={() => {
            setFilterState('done');
          }}
          style={{
            color: filterState === 'done' ? color : 'white',
          }}
          className={`${styles.filterItem} ${filterState === 'done' ? activeClass : ''}`}
        >
          {isReady && t('seasonZero:GAME_ROADMAP.general.done')}
        </Typography>
      </div>
    </div>
  );
};

export default CardItemHeader;
