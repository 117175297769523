export function isEmpty(value: unknown): boolean {
  if (value == null) return true; // null or undefined

  if (typeof value === 'string') return value.trim().length === 0;

  if (Array.isArray(value)) return value.length === 0;

  if (typeof value === 'object' && value.constructor === Object) {
    return Object.keys(value as Record<string, unknown>).length === 0;
  }

  return false;
}
