import React, { useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Button, Typography } from '@/components/Atoms';
import { IdeaSvg } from '@/components/Svg';
import { EAuthVarient } from '@/types/Authentication';
import { useStore } from '@/zustand/store/store';
import styles from './header.module.scss';
import SubmitIdeaModal from '../RoadMapItems/components/SubmitIdeaModal';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';

const Header = () => {
  const { t } = useTranslation('home');
  const { setAuthModalVarient } = useStore();
  const [modalVisible, setModalVisible] = useState(false);
  const { isLogin } = useAuthStatuses();
  const handleShowSubmitIdeaModal = () => {
    if (isLogin) {
      setModalVisible(true);
    } else {
      setAuthModalVarient(EAuthVarient.SIGN_IN);
    }
  };

  const gtmClassSubmitStart = isLogin ? 'gtm-roadmap-submit-start-login' : 'gtm-roadmap-submit-start-logout';

  return (
    <>
      <div className={styles.container}>
        <div className='flex gap-2'>
          <Typography className={styles.title}>LandRocker {t('home:roadmap.title')}</Typography>
          <div className='w-[60px] bg-red-500 rounded-lg text-white font-bold flex justify-center items-center gap-2 text-[12px] h-fit p-1'>
            <div className='w-[6px] h-[6px] rounded-full bg-white'></div>
            <Typography className='uppercase'>{t('home:roadmap.live')}</Typography>
          </div>
        </div>

        <div className='flex items-center gap-2'>
          <Typography className={styles.desc}>{t('home:roadmap.sub_title')}</Typography>
          <div className='w-[100px] h-[25px] md:w-[116px] md:h-[32px]'>
            <Image src='/images/247.png' width={'116px'} height={'32px'} alt='24/7 image' />
          </div>
        </div>
        <Typography className='mt-4 text-[#AAAAAA] text-[13px] md:text-[15px]'>{t('home:roadmap.description')} </Typography>
        <div
          className='w-full max-w-[900px] bg-[#1D2027] mt-[18px] rounded-lg h-auto py-4 md:py-4 px-3 md:px-5 flex 
        flex-col md:flex-row justify-center items-center gap-4 '
        >
          <div className='w-[31px] h-[50px] hidden md:flex'>
            <IdeaSvg />
          </div>
          <Typography className='text-[#AAAAAA] text-[13px] md:text-[15px]'>
            {t('home:roadmap.info_first')}{' '}
            <a
              onClick={handleShowSubmitIdeaModal}
              className={classNames('underline text-[#FDB74E] cursor-pointer hover:text-[#fd804e]', gtmClassSubmitStart)}
            >
              {t('home:roadmap.submit_here')}!
            </a>{' '}
            {t('home:roadmap.info_second')}!
          </Typography>
          <Button
            onClick={handleShowSubmitIdeaModal}
            className={classNames(
              'bg-white text-[#1D2027] button-hover-effect rounded-[4px] text-[14px] w-[102px] h-[32px] font-bold',
              gtmClassSubmitStart,
            )}
          >
            {t('home:roadmap.submit')}
          </Button>
        </div>
        <SubmitIdeaModal
          opened={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
        />

        {/* <Typography className={classNames(styles.desc, 'mt-4')}>
          Have an idea? <a className='text-white underline cursor-pointer'>Submit it here!</a>
        </Typography>
        <Typography className={styles.desc}>
          We’ll review your ideas and, if they align with our schedule and project goals, add them to the roadmap. Thank you for contributing to the
          future of LandRocker!
        </Typography> */}
      </div>
    </>
  );
};

export default Header;
