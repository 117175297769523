import React from 'react';
import { Typography } from '@/components/Atoms';
import { CustomModal } from '@/components/Molecules';
import { useResponsive } from '@/hooks';
import FormContent from './components';
import styles from "./SubmitIdeaModal.module.scss";

const SubmitIdeaModal = ({ opened, onClose }) => {
  const { isMobile } = useResponsive();
  const bodyDefaultStyle = { backgroundColor: '#121419!important', maxHeight: '90%!important', borderRadius: '16px' };
  const contentDefaultStyle = { backgroundColor: '#121419!important', overflowY: 'visible!important', borderRadius: '16px' };
  return (
    <CustomModal
      contentStyle={contentDefaultStyle}
      bodyStyle={bodyDefaultStyle}
      closeOnClickOutside={false}
      visible={opened}
      // fullScreen={isMobile ? true : false}
      centered
      onClose={onClose}
    >
      <div className='2xl:w-[1100px] xl:w-[1000px]'>
        {/* <div className={styles.titleContainer}>
          <Typography Component='div' className={styles.title} isBold>
            Submit an idea
          </Typography>
          <Typography Component='div' className={styles.desc}>
            If you have an idea you think will help LandRocker to grow, please submit it here. we will review and add it to the roadmap if it fits
            within our scope. we value our community's feedback and insight. thanks for being a part of it all.
          </Typography>
        </div> */}

        <FormContent onClose={onClose} />
      </div>
    </CustomModal>
  );
};

export default SubmitIdeaModal;
