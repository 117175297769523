import React, { useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import InputMask from 'react-input-mask';
import { MantineProvider, Popover } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import api from '@/api/services/gameRoadMap';
import { Typography } from '@/components/Atoms';
import LoadingBalls from '@/components/Common/LoadingBalls';
import { CalenderSvg, UploadIdeaSvg } from '@/components/Svg';
import { useStore } from '@/zustand/store/store';
import styles from './form.module.scss';

const FormContent = ({ onClose }) => {
  const currentDatePlus7 = moment().add(7, 'days').format('MM/DD/YYYY');

  const [value, setValue] = useState<string>(currentDatePlus7);

  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(null);
  const { setIsShowToast } = useStore();

  const handleDrop = (files: File[]) => {
    setFile(files[0]);
  };

  const initialValues = {
    title: '',
  };

  const onSubmit = (values) => {
    const { additionalInfo, ideaOrigin, title } = values;
    const [month, day, year] = value?.split('/');
    // @ts-ignore
    const date2 = new Date(Date.UTC(year, month - 1, day));
    const timestamp = date2.getTime();
    setLoading(true);
    let formData = new FormData();
    formData.append('image', file);
    formData.append('title', title);
    // formData.append('expected_date', `${timestamp}`);
    formData.append('idea_origin', ideaOrigin);
    formData.append('additional_information', additionalInfo);
    api
      .createIdea(formData)
      .then(() => {
        setLoading(false);
        onClose();
        setIsShowToast({
          isShow: true,
          message: 'The idea has been submitted.',
          method: 'success',
        });
      })
      .catch((error) => {
        setIsShowToast({
          isShow: true,
          message: error.response.data.error,
          method: 'error',
        });

        setLoading(false);
      });
  };

  const handleDateChange = (dateInpute) => {
    const formattedDate = moment(dateInpute).format('MM/DD/YYYY');
    setValue(formattedDate);
  };

  const formikRef = useRef(null);

  const isDisabled = (values) => {
    const { additionalInfo, ideaOrigin, title } = values;
    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
    if (additionalInfo && ideaOrigin && title && file) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={styles.list}>
      <div className={styles.titleContainer}>
        <Typography Component='div' className={styles.title} isBold>
          Submit an idea
        </Typography>
        <Typography Component='div' className={styles.desc}>
          If you have an idea you think will help LandRocker to grow, please submit it here. we will review and add it to the roadmap if it fits
          within our scope. we value our community's feedback and insight. thanks for being a part of it all.
        </Typography>
      </div>
      <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => (
          <Form className='flex flex-col gap-y-7'>
            <div className='sm:flex sm:flex-row flex-col gap-6'>
              <div className='flex flex-col sm:w-[50%] w-full'>
                <label htmlFor='title' className={styles.label}>
                  <Typography className={styles.typography}>Idea title</Typography>
                </label>
                <Field className={styles.field} placeholder='Enter the name of the idea here' type='text' id='title' name='title' />
              </div>

              <div className='flex flex-col sm:w-[50%] w-full mt-[24px] sm:mt-0'>
                <label htmlFor='upload' className={styles.label}>
                  <Typography className={styles.typography}>Upload picture</Typography>
                </label>
                <Dropzone onDrop={handleDrop} maxSize={5 * 1024 ** 2} accept={IMAGE_MIME_TYPE} className={styles.uploadButton}>
                  <div className='flex flex-row items-center justify-between'>
                    <Typography className={`${styles.mdText} ${file ? '!text-white' : '!text-[#727272]'}`} Component='div'>
                      {file ? file?.name : ' Upload picture Idea'}
                    </Typography>
                    <div className='w-[20px] h-[20px]'>
                      <UploadIdeaSvg />
                    </div>
                  </div>
                </Dropzone>
              </div>
              {/* <div className='flex flex-col sm:w-[40%] w-full mt-[24px] sm:mt-0'>
                  <label htmlFor='date' className={styles.label}>
                    <Typography className={styles.typography}>Expected date</Typography>
                  </label>
                  <Field className='!bg-black :active:!bg-black' name='date'>
                    {({ field }) => (
                      <MantineProvider theme={{ colorScheme: 'dark' }}>
                        <Popover position='bottom' withArrow shadow='md'>
                          <Popover.Target>
                            <button type='button' className='relative'>
                              <InputMask
                                defaultValue={value}
                                key={value}
                                mask='99/99/9999'
                                placeholder='Enter Expected date'
                                className={styles.field}
                                name='InputMask'
                                autoComplete='off'
                              />

                              <div className='w-[20px] h-[20px] absolute top-3 right-4'>
                                <CalenderSvg />
                              </div>
                            </button>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <DatePicker value={value} onChange={handleDateChange} />
                          </Popover.Dropdown>
                        </Popover>
                      </MantineProvider>
                    )}
                  </Field>
                </div> */}
            </div>

            {/* <div className='flex flex-col'>
                <label htmlFor='upload' className={styles.label}>
                  <Typography className={styles.typography}>Upload picture</Typography>
                </label>
                <Dropzone onDrop={handleDrop} maxSize={5 * 1024 ** 2} accept={IMAGE_MIME_TYPE} className={styles.uploadButton}>
                  <div className='flex flex-row items-center justify-between'>
                    <Typography className={`${styles.mdText} ${file ? '!text-white' : '!text-[#727272]'}`} Component='div'>
                      {file ? file?.name : ' Upload picture Idea'}
                    </Typography>
                    <div className='w-[20px] h-[20px]'>
                      <UploadIdeaSvg />
                    </div>
                  </div>
                </Dropzone>
              </div> */}

            <div className='flex flex-col'>
              <label htmlFor='ideaOrigin' className={styles.label}>
                <Typography className={styles.typography}>Idea origin</Typography>
              </label>
              <Field className={styles.field} placeholder='Enter Idea origin' type='text' id='ideaOrigin' name='ideaOrigin' />
            </div>

            <div className='flex flex-col'>
              <label htmlFor='additionalInfo' className={styles.label}>
                <Typography className={styles.typography}>Additional Information</Typography>
              </label>
              <Field as='textarea' className={styles.textarea} placeholder='Enter Additional Information' id='additionalInfo' name='additionalInfo' />
            </div>
            <button
              disabled={isDisabled(values)}
              className={classNames(
                `${styles.submitButton} ${isDisabled(values) ? styles.submitButtonDisabled : styles.submitButtonEnabled}`,
                isDisabled(values) ? 'gtm-roadmap-submit-end-disabled' : 'gtm-roadmap-submit-end-active',
              )}
              type='submit'
            >
              {loading ? (
                <LoadingBalls />
              ) : (
                <Typography className={styles.typographyLarge} isBold>
                  Submit idea
                </Typography>
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormContent;
