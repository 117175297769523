import React from 'react';
import classNames from 'classnames';

export interface CustomRoadmapProgressProps {
  maxStep: number;
  currentStep: number;
}

const CustomRoadmapProgress: React.FC<CustomRoadmapProgressProps> = ({ currentStep, maxStep }) => {
  const steps = Array.from({ length: maxStep }, (_, index) => (
    <div
      key={index}
      className={classNames('h-1 flex-1 w-full rounded-sm', {
        'bg-[#00FF00]': index < currentStep,
        'bg-[#707070]': index >= currentStep,
      })}
    />
  ));

  return <div className='flex space-x-1 mt-2 mx-3'>{steps}</div>;
};

export default CustomRoadmapProgress;
