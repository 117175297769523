/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import api from '@/api/services/gameRoadMap';
import { Typography } from '@/components/Atoms';
import LoadingBalls from '@/components/Common/LoadingBalls';
import { useStore } from '@/zustand/store/store';
import styles from './cardItem.module.scss';
import CardItemProgressContent from './CardItemProgressContent';

const ProgressContent = () => {
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const { gameRoadMapModalData } = useStore();

  useEffect(() => {
    setIsLoading(true);
    api
      .getSubItem(gameRoadMapModalData.Id)
      .then((res) => {
        setData(res.data.result);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className='flex h-full min-w-[330px] min-h-[400px] justify-center items-center'>
        <LoadingBalls />
      </div>
    );
  }

  return (
    <>
      <div className={styles.progressContainer}>
        <div className={styles.listContainer}>
          {/* <div className={styles.headerContainer}>
            <Typography className={styles.headerText} Component='div'>
              Adding environment assets
            </Typography>
          </div> */}
          <div className={classNames('p-4 border-[#323640] border bg-[#1A1B20] rounded-xl', data.length === 0 && 'mt-10')}>
            <CardItemProgressContent item={gameRoadMapModalData} isParentItem />
          </div>
          {data &&
            data?.map((item, i) => {
              return (
                <div key={i} className='md:p-4 '>
                  <CardItemProgressContent item={item} />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default ProgressContent;
