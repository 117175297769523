import { AllGameRoadmapsResponseType } from '@/types/gameRoadMap';
import { axiosInstanceTwo } from '../serviceTest';
import { roadmapQueryType } from '@/components/GameRoadMap/RoadMapItems';

const baseURL = process.env.NEXT_PUBLIC_BASE_ADMINHUB;

const getGameRoadMap = (params: roadmapQueryType) =>
  axiosInstanceTwo.get<AllGameRoadmapsResponseType>(`/game-roadmap/`, {
    params: {
      page: params.page_number,
      page_size: params.page_size,
      ...(params.type_name && { road_map_type_name: params.type_name }), // Conditionally include road_map_type_name if defined
      for_test: false,
      active: true,
    },
    baseURL,
  });
const getSubItem = (id: string) => axiosInstanceTwo.get(`game-roadmap/get-sub-list/active/${id}`, { baseURL });
const createIdea = (formData) => axiosInstanceTwo.post(`idea/create`, formData, { baseURL });

const api = {
  getGameRoadMap,
  getSubItem,
  createIdea,
};

export default api;
