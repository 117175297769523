/* eslint-disable @next/next/no-img-element */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import moment from 'moment';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { Typography } from '@/components/Atoms';
import { CustomModal } from '@/components/Molecules';
import { GreenTick } from '@/components/Svg';
import { useStore } from '@/zustand/store/store';
import ProgressContent from './components/ProgressContent';
import styles from './ExtraInformationModal.module.scss';
import 'react-medium-image-zoom/dist/styles.css';
import useTranslationStatus from '@/hooks/useTranslationStatus';
import { isEmpty } from '@/utils/isEmpty';

const ExtraInformationModal = () => {
  const { t } = useTranslation('seasonZero');
  const { gameRoadMapModalData, setModalData } = useStore();
  const { isReady } = useTranslationStatus();

  if (!gameRoadMapModalData) {
    return;
  }
  const { Description, EndDate: doneDate, ImageThumbnail, Title, IsDone, MaxStep, Prefix, StartDate } = gameRoadMapModalData;

  const formattedStartDate = moment(StartDate).format('MMMM D, YYYY');

  const renderSingleContent = () => {
    return (
      <div className={styles.contentContainer}>
        <div className={styles.shadowContainer}>
          <div className={`${styles.shadow}`}></div>
        </div>
        <div className={styles.bodyContainer}>
          <div className='flex flex-col items-start '>
            <div className={styles.imageContainer}>
              {/* <Zoom>
                <img
                  src={`${ImageThumbnail ? Prefix + ImageThumbnail : '/images/NFT.svg'}`}
                  alt='planet image'
                  loading={'lazy'}
                  className='rounded-lg object-contain'
                />
              </Zoom> */}
              <Image
                src={`${ImageThumbnail ? Prefix + ImageThumbnail : '/images/NFT.svg'}`}
                alt='planet image'
                layout='fill'
                objectFit='contain'
                placeholder='blur'
                blurDataURL='/images/NFT.svg'
                loading={'lazy'}
                className='rounded-lg contain'
              />
            </div>
            {IsDone ? (
              <div className='mt-[5%] flex w-auto justify-start'>
                <div className={styles.isDoneContainer}>
                  <Typography className={styles.title} Component={'div'}>
                    {isReady && t('seasonZero:GAME_ROADMAP.general.accomplishedOn')}
                  </Typography>
                  <Typography className={styles.date} Component={'div'}>
                    {moment(doneDate).format('MMMM D, YYYY')}
                  </Typography>
                  <div className={styles.tickContainer}>
                    <GreenTick className='!text-white' stroke='black' />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
          <div className={styles.descriptionContainer}>
            <Typography className={styles.title} Component={'div'}>
              {Title}
            </Typography>
            <div className={styles.descContainer}>
              <Typography className={styles.desc} Component={'div'}>
                {Description}
              </Typography>
            </div>
            <div className='text-[12px] md:text-[14px] text-[#808080] flex'>Start on {formattedStartDate}</div>
          </div>
        </div>
      </div>
    );
  };
  const renderProgressContent = () => {
    return (
      <div
        className={
          '@apply lg:px-5 overflow-visible mt-[3%] lg:min-w-[780px] lg:max-h-[700px] xl:max-h-[600px] 2xl:max-h-[700px] md:min-h-[400px] h-auto w-full xl:w-[980px] lg:w-[880px] md:w-[580px] pb-[2%]'
        }
      >
        <div className={styles.shadowContainer}>{/*<div className={`${styles.shadow}`}></div> */}</div>
        <ProgressContent />
      </div>
    );
  };
  return (
    <CustomModal
      visible={!isEmpty(gameRoadMapModalData)}
      // handle modal condition for 2 modal
      // fullScreen={isMobile && MaxStep ? true : false}
      centered
      onClose={() => {
        setModalData(null);
      }}
    >
      {renderProgressContent()}
    </CustomModal>
  );
};

export default ExtraInformationModal;
