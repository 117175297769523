import React from 'react';
import Zoom from 'react-medium-image-zoom';
import moment from 'moment';
import Image from 'next/image';
import { Typography } from '@/components/Atoms';
import { GreenTick } from '@/components/Svg';
import { RoadmapType } from '@/types/gameRoadMap';
import styles from '../cardItem.module.scss';
import 'react-medium-image-zoom/dist/styles.css';
// eslint-disable-next-line import/order
import RoadmapVideo from './RoadmapVideo';

interface ICardItemProgressContentProps {
  item: RoadmapType;
  isParentItem?: boolean;
}

const CardItemProgressContent = (props: ICardItemProgressContentProps) => {
  const { item, isParentItem } = props;
  const { Title, Description, IsDone, EndDate, Prefix, Image: ImageUrl, DefaultImage, StartDate } = item;
  const timestamp = EndDate;
  const formattedDate = moment(timestamp).format('MMMM, YYYY');
  const formattedStartDate = moment(StartDate).format('MMMM, YYYY');
  return (
    <div className={styles.listItem}>
      <div className='w-full flex flex-wrap lg:flex-row gap-2 !overflow-hidden'>
        <div className={`${styles.greenBar} ${IsDone ? 'bg-[#00FF00]' : 'bg-[#707070]'}`} />

        <div className='lg:w-[144px] lg:h-[145px] w-[90px] h-[90px] relative !overflow-hidden'>
          {item.Video ? (
            <RoadmapVideo
              className='lg:w-[144px] lg:h-[145px] w-[90px] h-[90px]'
              posterUrl={ImageUrl ? `${Prefix}${ImageUrl}` : DefaultImage}
              url={item.Video ? Prefix + item.Video : undefined}
            />
          ) : (
            <Zoom>
              <div className='h-full w-full relative'>
                <img src={ImageUrl ? `${Prefix}${ImageUrl}` : DefaultImage} alt='fuel image' className='rounded-[6px] object-cover relative' />
              </div>
            </Zoom>
          )}

          {!isParentItem && !IsDone ? (
            <div className={styles.overlay}>
              <Image src={'/images/asset1.png'} layout='fill' objectFit='contain' alt='fuel image' className='rounded-[6px]' />
            </div>
          ) : (
            false
          )}
        </div>

        <div className='w-[calc(100%-120px)] lg:w-[68%] flex flex-col lg:flex-col lg:mb-3 self-end border-red-900 lg:ml-[10px] xl:ml-[23px]'>
          <Typography Component='div' className={styles.ideaText}>
            {Title}
          </Typography>

          <div className='w-full lg:flex hidden'>
            <Typography Component='div' className={styles.descriptionText}>
              {Description}
            </Typography>
          </div>
          <div className='flex justify-between flex-wrap mt-1'>
            <div className='flex gap-2'>
              <Typography Component='div' className={'text-[13px] lg:text-[14px] text-[#717275]'}>
                Start on
              </Typography>
              <div className='text-[14px] text-white'>{formattedStartDate}</div>
            </div>
            {IsDone && EndDate ? (
              <div className={styles.buttonContainer}>
                <div className={styles.button}>
                  <Typography Component='div' className={'text-[13px] lg:text-[14px] text-[#717275]'}>
                    Done on
                  </Typography>
                  <Typography Component='div' className={'text-[13px] lg:text-[14px] text-[#00FF00]'}>
                    {formattedDate}
                  </Typography>
                  <div className='w-[20px] h-[20px] bg-[#00FF00] rounded-full'>
                    <GreenTick className='!text-white' stroke='black' />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </div>

        <div className='w-full flex lg:hidden'>
          <Typography Component='div' className={styles.descriptionText}>
            {Description}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CardItemProgressContent;
